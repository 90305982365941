.transactionFormModal {
  max-width: 600px;
  color: white;
}

.stepProgressBar {
  padding: 0 0 2rem 0 !important;
  font-size: 10px;
  font-family: 'Druk Wide, sans-serif';
  font-weight: 400;
}

.stepProgressBar > ul > li > span:first-child {
  color: black !important;
}

.inputGroupText {
  border-radius: 0.25rem 0 0 0.25rem;
  border-right: none;
  font-size: 10px;
  font-family: 'Druk Wide, sans-serif';
  font-weight: 400;
}

.inputGroupTextArguments {
  border-radius: 12px 0 0 12px;
  border-right: none;
  font-size: 10px;
  font-family: 'Druk Wide, sans-serif';
  font-weight: 400;
}

.functionName {
  white-space: nowrap;
}

.modalHeader {
  background-color: black;
  font-family: 'Druk Wide, sans-serif';
  font-weight: 400;
  font-size: 14px !important;
}

.modalBody {
  background-color: black;
}

.stepButton {
  height: 32px;
  color: white;
  background-color: black;
  border-radius: 12px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: 'Druk Wide, sans-serif';
  font-weight: 400;
  font-size: 12px;
  border: 1px solid white !important;
}
.stepButton:active,
.stepButton:focus,
.stepButton:hover {
  background: #222429 !important;
  box-shadow: none !important;
}

.stepButton:disabled {
  background: #222429 !important;
  box-shadow: none !important;
  cursor: not-allowed !important;
}

.formLabel {
  font-family: 'Druk Wide, sans-serif';
  font-weight: 400;
  font-size: 10px;
}

.formValue {
  font-family: 'Graphik Regular';
  font-weight: 500;
  font-size: 14px;
}

.formValue a {
  color: var(--beans-color-primary);
}

.proposalInput {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  /* width: auto; */
  height: 40px;
  color: #8a90a3;
  border-radius: 12px !important;
  box-shadow: inset 0px 0px 0px 1px #fff;
  background-color: #222429;
  outline: none !important;
  box-shadow: none !important;
  font-family: 'Druk Wide, sans-serif';
  font-weight: 400;
  font-size: 12px;
  transition: all 0.2s ease-in-out;
  border: none;
}

.proposalInput:focus {
  box-shadow: inset 0px 0px 0px 1px var(--brand-cool-dark-text) !important;
}

.proposalInput:focus-within {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  height: 40px;
  color: white;
  border-radius: 12px !important;
  box-shadow: inset 0px 0px 0px 1px #676c7a !important;
  background-color: #222429;
  font-family: 'Druk Wide, sans-serif';
  font-weight: 400;
  font-size: 12px;
  transition: all 0.2s ease-in-out;
  border: none;
}

.proposalInput[type='file'] {
  height: 40px;
}

.proposalInput[type='file']::-webkit-file-upload-button {
  height: 40px;
}

.proposalInputArguments {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  /* width: auto; */
  height: 40px;
  color: #8a90a3;
  /* border-radius: 12px !important; */
  border-top-right-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
  box-shadow: inset 0px 0px 0px 1px #fff;
  background-color: #222429;
  outline: none !important;
  box-shadow: none !important;
  font-family: 'Druk Wide, sans-serif';
  font-weight: 400;
  font-size: 12px;
  transition: all 0.2s ease-in-out;
  border: none;
}

.proposalInputArguments:focus {
  box-shadow: inset 0px 0px 0px 1px var(--brand-cool-dark-text) !important;
}

.proposalInputArguments:focus-within {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  height: 40px;
  color: white;
  border-top-right-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
  box-shadow: inset 0px 0px 0px 1px #676c7a !important;
  background-color: #222429;
  font-family: 'Druk Wide, sans-serif';
  font-weight: 400;
  font-size: 12px;
  transition: all 0.2s ease-in-out;
  border: none;
}

.proposalInputArguments[type='file'] {
  height: 40px;
}

.proposalInputArguments[type='file']::-webkit-file-upload-button {
  height: 40px;
}
