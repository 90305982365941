.wrapper h1 {
  font-family: 'Druk Wide, sans-serif';
  font-weight: 400;
  font-size: 5rem;
  text-align: center;
  color: white;
  margin: 0 auto;
  height: 100% !important;
}

.verticalcenter {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.wrapper {
  /* display: flex;
  align-items: center;
  text-align: center; */
}

/* background: linear-gradient(325deg, var(--beans-color-primary), var(--beans-color-secondary)); */

.coloranimator {
  background: linear-gradient(
    125deg,
    var(--beans-color-primary),
    var(--beans-color-secondary),
    var(--beans-color-primary),
    var(--beans-color-secondary),
    var(--beans-color-primary),
    var(--beans-color-secondary)
  );
  background-size: 1200% 1200%;

  -webkit-animation: AnimationName 20s ease infinite;
  -moz-animation: AnimationName 20s ease infinite;
  animation: AnimationName 20s ease infinite;
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 93% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}
@-moz-keyframes AnimationName {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 93% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}
@keyframes AnimationName {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 93% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}

.col:first-of-type {
  background: red;
}

.col:last-of-type {
  border: green solid;
}

@media (max-width: 992px) {
  .wrapper {
    /* padding: 2rem; */
    /* display: block; */
  }

  .wrapper h1 {
    font-size: 3.75rem;
  }
}

@media (min-width: 992px) {
  .wrapper h1 {
    font-size: 42px;
    font-weight: 700;
    /* margin-left: 2rem; */
  }
}

@media (max-width: 992px) {
  .wrapper h1 {
    font-size: 1.5rem;
    /* margin-left: 2rem; */
  }
  .bannerSection {
    /* margin-top: 20px !important; */
    /* background: #15161A; */
  }
}

.bannerSection {
  padding-top: 160px;
  padding-bottom: 160px;
  /* background: #15161A; */
}

.bean {
  /* height: 95%;
  width: 95%; */
}
