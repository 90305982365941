.voteModal {
  max-width: 380px;
}

.voteModalText {
  font-size: 14px;
  font-weight: 700;
  font-family: 'Druk Wide, sans-serif';
  padding: 0;
  color: white !important;
}

.modalHeader {
  background-color: black;
  font-family: 'Druk Wide, sans-serif';
  font-weight: 400;
  font-size: 14px !important;
}

.modalTitleWrapper {
  color: var(--brand-black);
  /* border-radius: 12px !important; */
}

.modalTitleWrapper h1 {
  font-size: xx-large;
  margin-left: 2rem;
  color: white;
}

.modalBody {
  background-color: black;
  color: white;
}

.voteButton {
  height: 32px;
  color: white;
  background-color: black;
  border-radius: 12px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: 'Druk Wide, sans-serif';
  font-weight: 400;
  font-size: 12px;
  border: 1px solid white !important;
  box-shadow: none;
}
.voteButton:focus,
.voteButton:focus-visible,
.voteButton:hover,
.voteButton:active .voteButton:hover {
  background: #222429 !important;
  box-shadow: none !important;
  box-shadow: none;
}

.spinner {
  margin-top: 2px;
}
