.beanButtonCool {
  /* width: 140px; */
  height: 32px;
  color: white;
  background-color: black;
  border-radius: 12px;
  margin-right: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: 'Druk Wide, sans-serif';
  font-weight: 400;
  font-size: 12px;
  cursor: pointer;
  vertical-align: middle;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  transition: all 0.15s ease-in-out;
  border: 1px solid white !important;
}

.beanButton {
  height: 32px;
  color: var(--brand-cool-dark-text);
  background-color: var(--brand-cool-accent);
  border-radius: 12px;
  margin-right: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: 'Druk Wide, sans-serif';
  font-weight: 400;
  cursor: pointer;
  vertical-align: middle;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  transition: all 0.15s ease-in-out;
}

.beanButtonCool:hover,
.beanButton:hover {
  background: #222429 !important;
  box-shadow: none !important;
}

.beanButton:active {
  color: black;
}

.beanButtonContents {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.buttonIcon {
  margin-right: 5px;
}
