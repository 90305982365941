.wrapper {
  display: inline-block;
}

.wrapper h1 {
  font-family: 'Druk Wide, sans-serif';
  font-weight: 400;
  font-size: 30px;
  margin-bottom: 10px;
}

@media (max-width: 992px) {
  .wrapper h1 {
    font-size: 36px;
    line-height: 47px;
  }

  .wrapper {
    display: flex;
    /* justify-content: center; */
  }
}
