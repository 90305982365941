.heading {
  display: inline-block;
  font-weight: bold;
  font-size: 3rem;
}

.headerRow span {
  color: white;
  font-size: 56px;
  font-family: 'Druk Wide, sans-serif';
  text-align: center;
  font-weight: 400;
}

.heading {
  color: white;
  font-size: 42px;
  font-family: 'Druk Wide, sans-serif';
  text-align: center;
  font-weight: 400;
  margin-left: 100px;
  margin-right: 100px;
}

.subheading {
  color: #8a90a3;
  font-family: 'Graphik Regular';
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  margin-left: 115px;
  margin-right: 115px;
}

.boldText {
  font-family: 'Druk Wide, sans-serif';
  font-weight: 400;
}

p {
  padding-top: 1rem;
}

@media (max-width: 992px) {
  .section {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .heading {
    color: white;
    font-size: 24px;
    font-family: 'Druk Wide, sans-serif';
    text-align: center;
    font-weight: 400;
    margin-left: 0px;
    margin-right: 0px;
  }

  .subheading {
    margin-left: 0px;
    margin-right: 0px;
  }
}

.wrapper {
  margin-left: auto;
  margin-right: auto;
}

.treasuryInfoCard {
  margin-bottom: 3rem;
  border-radius: 20px;
  border: 1px solid #e2e3e8;
}

.treasuryAmtWrapper {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.usdTreasuryAmt {
  padding-top: 0.2rem;
}

.ethTreasuryAmt {
  display: flex;
  height: 3rem;
  min-width: 9rem;
  padding-top: 0.2rem;
}

.ethTreasuryAmt h1 {
  font-size: 36px;
  font-family: 'Druk Wide, sans-serif';
}

.usdBalance {
  font-size: 36px;
  font-family: 'Druk Wide, sans-serif';
}

.ethSymbol {
  font-family: 'Druk Wide, sans-serif' !important;
  margin-right: 0.5rem;
}

.usdBalance {
  color: var(--brand-gray-light-text);
  font-family: 'Druk Wide, sans-serif';
}

.treasuryInfoText {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  font-family: 'Druk Wide, sans-serif';
  font-weight: 500;
}

@media (min-width: 992px) {
  .treasuryAmtWrapper {
    border-right: 2px solid #e2e3e8;
  }

  .ethTreasuryAmt {
    border-right: 2px solid #e2e3e8;
  }
}

@media (max-width: 992px) {
  .headerRow h1 {
    color: white;
    font-size: 32px;
    font-family: 'Druk Wide, sans-serif';
  }

  .headerRow span {
    color: white;
    font-size: 32px;
    font-family: 'Druk Wide, sans-serif';
    text-align: center;
    font-weight: 400;
  }
}
