.section span {
  font-size: 3rem;
  font-weight: 400;
  font-family: 'Druk Wide, sans-serif';
}

.section h4 {
  font-family: 'Druk Wide, sans-serif';
  font-weight: 400;
  font-size: 12px;
}

.section h2 {
  font-family: 'Druk Wide, sans-serif', 'Helvetica Neue', Arial !important;
  font-weight: 400;
  font-size: 22px;
  margin-bottom: 0px !important;
}

.wrapper {
  padding-left: 0rem;
  padding-right: 0rem;
}

@media (max-width: 992px) {
  .section h4 {
    font-size: 14px;
    margin-bottom: 0px;
    /* margin-top: 6px; */
  }

  .section h2 {
    font-size: 14px;
    margin-bottom: 0px;
    /* margin-top: 6px; */
  }

  .section {
    justify-content: space-between;
  }

  .currentBid {
    margin-right: 0.5rem;
  }

  .wrapper {
    display: flex;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .leftCol {
    padding-left: 0.5rem;
  }
}
