.img {
  image-rendering: pixelated;
  image-rendering: -moz-crisp-edges;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  vertical-align: middle;
  border-radius: 17px;
}

.imgWrapper {
  position: relative;
  padding-top: 100%;
  width: 100%;
  height: 0;
}

.lottie {
  border-radius: 12px;
}

.popover {
  width: 100%;
  background-color: black;
  color: white;
  border-radius: 12px;
  font-weight: 500;
  font-family: 'Graphik Regular';
  font-size: 14px;
}

.popoverheader {
  background-color: black;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border: 1px solid #454852 !important;
  color: white;
  font-weight: 500;
  font-family: 'Graphik Regular';
  font-size: 14px;
}

.popoverbody {
  background-color: black;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border: 1px solid #454852 !important;
  color: white;
  font-weight: 500;
  font-family: 'Graphik Regular';
  font-size: 14px;
}
