.wrapper {
  margin-left: 0.5rem;
  padding-left: 1.5rem;
}

.youCopy {
  /* margin-top: 0.25rem; */
}

.verifyButton {
  height: 40px;
  color: black;
  border: transparent;
  background-color: var(--beans-color-primary);
  font-family: 'Druk Wide, sans-serif';
  font-weight: 400;
  border-radius: 12px;
  font-size: 12px;
}

.verifyButton:hover,
.verifyButton:active,
.verifyButton:focus,
.verifyButton:disabled {
  filter: brightness(var(--beans-brightness-percentage)) !important;
  outline: none !important;
  box-shadow: none !important;
  color: black;
}

.section h4 {
  font-family: 'Druk Wide, sans-serif';
  font-size: 12px;
}

.section h2 {
  font-family: 'Druk Wide, sans-serif';
  font-weight: 400;
  font-size: 22px;
}

.leftCol {
  font-family: 'Druk Wide, sans-serif';
}

.leftCol h4 {
  font-weight: 400;
}

@media (max-width: 992px) {
  .section h4 {
    font-size: 14px;
  }

  .section h2 {
    font-size: 14px;
  }

  .section {
    justify-content: space-between;
  }

  .wrapper {
    margin-top: 0px;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .leftCol {
    padding-left: 0.5rem;
  }

  .winnerContent span {
    margin-right: 0.5rem;
  }

  .wrapper {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  .verifyButton {
    width: 100%;
    height: 3rem;
    border-radius: 12px;
    font-weight: normal;
    font-size: 12px;
  }

  .verifyLink {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .youCopy {
    padding-right: 0.25rem;
  }
}

.beanName {
  display: flex;
  flex-flow: row nowrap;
  gap: 6px;
  align-items: center;
}

.beanName > div {
  padding-top: -2px;
}

.beanName span {
  letter-spacing: 0.2px;
  font-family: 'Druk Wide, sans-serif';
  font-weight: 400;
}
