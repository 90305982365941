.proposals {
  display: flex;
  flex-direction: column;
}
/* 
.proposals > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
} */

.heading {
  font-family: 'Druk Wide, sans-serif';
  font-size: 30px;
  font-weight: 400;
  margin: 0 !important;
  color: white;
  line-height: 39px;
}

.generateBtn {
  font-family: 'Druk Wide, sans-serif';
  font-weight: 400;
  font-size: 10px;
  background-color: black;
  border: 1px solid var(--brand-cool-border);
  border-radius: 12px;
  color: var(--brand-warm-dark-text);
  padding: 0px 12px 0px 12px;
  transition: all 0.2s ease-in-out;
  color: white;
  height: 36px;
}

.generateBtn:focus,
.generateBtn:hover,
.generateBtn:active {
  background-color: #222429;
  border: 1px solid var(--brand-cool-border);
  box-shadow: none !important;
}

.generateBtnDisabled,
.generateBtnDisabled:focus,
.generateBtnDisabled:hover,
.generateBtnDisabled:active {
  font-family: 'Druk Wide, sans-serif';
  font-weight: 400;
  font-size: 10px;
  cursor: not-allowed;
  background-color: black;
  border: 1px solid var(--brand-cool-border);
  border-radius: 12px;
  color: var(--brand-warm-dark-text);
  padding: 0px 12px 0px 12px;
  transition: all 0.2s ease-in-out;
  color: white;
  height: 36px;
}

.proposalLink {
  height: 48px;
  padding: 1rem;
  margin-top: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #454852;
  box-sizing: border-box;
  border-radius: 16px;
  background: black;
  font-size: 10px;
  font-family: 'Druk Wide, sans-serif';
  font-weight: 400;
}

.proposalLink:hover {
  background: black;
  cursor: pointer;
}

.markdown h1 {
  font-size: 1.7rem;
  margin-top: 1rem;
  font-weight: bold;
}

.markdown h2 {
  font-size: 1.5rem;
  margin-top: 1rem;
  font-weight: bold;
}

.markdown h3 {
  font-size: 1.3rem;
}

.markdown img {
  max-width: 100%;
  height: auto;
}

.nullStateCopy {
  float: right;
  margin-left: auto;
  font-family: 'Druk Wide, sans-serif';
  font-weight: 400;
  color: var(--brand-gray-light-text);
  font-size: 10px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.btnWrapper {
  float: right;
}

@media (max-width: 992px) {
  .nullStateCopy {
    float: right;
    /* margin: auto !important; */
    /* padding-bottom: 10px; */
  }

  .proposalsHeaderGroup {
    /* display: flex; */
    /* flex-direction: row; */
    /* justify-content: space-between; */
    /* margin: auto; */
  }

  .proposalTitle {
    width: 65%;
    word-wrap: break-word;
    min-width: 65%;
  }

  .heading {
    font-family: 'Druk Wide, sans-serif';
    font-size: 16px;
    margin: 0 !important;
    color: white;
    padding-bottom: 10px;
  }
}

.nullBtnWrapper {
  float: right;
  min-width: 10rem;
}

.proposalId {
  color: #8a90a3;
  margin-right: 0.5rem;
}

.proposalTitle {
  width: 85%;
  color: #e6e6e6;
}

.proposalStatusWrapper {
  margin-left: 0.5rem;
}

.submitProposalButtonWrapper {
  /* justify-content: space-between; */
}

.noProposalsFound {
  background: #222429;
  color: #8a90a3;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Graphik Regular';
  line-height: 18px;
  border-radius: 12px;
  border: none;
  margin-bottom: 0px !important;
}
