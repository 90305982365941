.navBarBrand {
  position: relative;
  z-index: 2;
  padding: 1rem 0rem;
  transition: all 0.15s ease-in-out;
}

.navBarBrand:hover {
  transform: scale(0.95);
}
.collapseAdjust {
  padding-left: 80px !important;
}

.linkSpace {
  padding-right: 15px !important;
  padding-left: 15px !important;
}

.navBarLogo {
  width: 42px;
  height: 35px;
}

.navBarCustom {
  /* padding-bottom: 1rem; */
}

@media (max-width: 992px) {
  .beansNavCollapse {
    padding: 1em !important;
    margin: 0 -1em;
    border-bottom: 1px solid #8a90a3;
  }

  .navBarLogo {
    width: 42px;
    height: 35px;
  }

  .navBarCustom {
    margin-bottom: -1px;
    margin-right: -5px;
    padding-bottom: 0rem;
  }
}

.disconnectBtn {
  cursor: pointer;
  font-family: 'Druk Wide, sans-serif';
  font-weight: 400;
}

.disconnectBtn:hover,
.disconnectBtn:active,
.disconnectBtn:focus {
  cursor: pointer !important;
}

.greenStatusCircle {
  display: relative;
  width: 10px;
  height: 10px;
  background-color: #7fc59c;
  border-radius: 50%;
  margin-right: -30px;
  margin-left: 20px;
  margin-top: -20px;
  z-index: 3;
}

.beansNavLink,
.beansNavLink:active,
.beansNavLink:visited {
  font-family: 'Druk Wide, sans-serif';
  font-weight: 400;
  font-size: 0.9rem;
  padding: 0.3rem 0.3rem !important;
  color: black !important;
  text-decoration: none;
}

.testnet {
  color: white;
  font-family: 'Druk Wide, sans-serif';
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.3px;
  height: 18px;
}

.addressNavLink {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.brandAndTreasuryWrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}

.navBarToggle {
  height: 44px;
  padding: 0.25rem 0.5rem;
  /* margin-right: 12px; */
  background: black !important;
}

.navBarSocial {
  padding-left: 0px;
  padding-right: 10px;
  margin: auto;
  transition: all 0.15s ease-in-out;
}

.navBarSocial:hover {
  transform: scale(0.95);
}
