@tailwind base;
@tailwind components;
@tailwind utilities;

/* @layer base { */
@font-face {
  font-family: 'Druk Wide, sans-serif';
  src: url('./fonts/Test2/Druk-WideMedium.eot');
  src:
    url('./fonts/Test2/Druk-WideMedium.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Test2/Druk-WideMedium.woff2') format('woff2'),
    url('./fonts/Test2/Druk-WideMedium.woff') format('woff'),
    url('./fonts/Test2/Druk-WideMedium.svg#Druk-WideMedium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-stretch: expanded;
}
@font-face {
  font-family: 'Graphik Regular';
  src: url(./fonts/Graphik/Graphik-Regular.otf);
}
/* } */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  /* -moz-osx-font-smoothing: grayscale; */
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; */
}

/* @font-face {
  font-family: "Druk Wide, sans-serif";
  src: url('./fonts/Test2/Druk-WideMedium.eot');
	src: url('./fonts/Test2/Druk-WideMedium.eot?#iefix') format('embedded-opentype'),
		url('./fonts/Test2/Druk-WideMedium.woff2') format('woff2'),
		url('./fonts/Test2/Druk-WideMedium.woff') format('woff'),
		url('./fonts/Test2/Druk-WideMedium.svg#Druk-WideMedium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-stretch: expanded;
} */

.navbar-toggler.collapsed .navbar-toggler-icon {
  transform: scale(1);
  background-image: url(./assets/icons/hamburger_white.svg) !important;
}

.navbar-toggler .navbar-toggler-icon {
  transform: scale(0.7);
  background-image: url(./assets/icons/close_white.svg) !important;
}

.progress-bar.bg-success {
  background-color: #49d782 !important;
}

.progress-bar.bg-danger {
  background-color: #fc6666 !important;
}

.progress-bar.bg-info {
  background-color: #8a90a3 !important;
}

.progress {
  background-color: #222429 !important;
}

.accordion-toggle {
  background-color: gray;
  display: block;
  padding: 10px;
  margin: -10px -15px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.accordion-button.collapsed {
  color: white !important;
}

.accordion-button {
  color: var(--beans-color-primary) !important;
}

.modal-content {
  border: 1px solid #454852 !important;
  border-radius: 12px !important;
}

.modal-header {
  border: 1px solid #454852 !important;
  border-top-left-radius: 12px !important;
  border-top-right-radius: 12px !important;
  border-bottom: none !important;
}

.modal-body {
  border: 1px solid #454852 !important;
  border-bottom-left-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
  border-top: none !important;
}

.modal-title {
  color: white !important;
  font-size: 14px !important;
}

.btn-close {
  background-image: url('./assets/x-icon-white.png') !important;
}
