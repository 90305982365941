.link,
.link:visited,
.link:active {
  color: var(--beans-color-primary);
  text-decoration: underline;
}

.link:hover {
  color: var(--beans-color-primary);
  /* filter: brightness(var()); */
}

/* .bidRow {
  font-weight: 500;
  font-size: 15.5px;
  line-height: 21px;
  color: white;
} */

.currentBidCol {
  border-right: 0 !important;
}

.bidRow {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Graphik Regular';
  line-height: 18px;
  color: #8a90a3 !important;
  margin-left: 1rem;
  margin-right: 1rem;
}
