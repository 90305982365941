.privacyContainer {
  background-color: black !important;
}

.paragraphHeader {
  font-family: 'Druk Wide, sans-serif';
  font-weight: 700;
  background-color: black;
  box-shadow: none !important;
  font-size: 30px !important;
  color: #ffffff !important;
}

.paragraphList li {
  font-size: 14px !important;
  font-weight: 500 !important;
  font-family: 'Graphik Regular' !important;
  line-height: 18px !important;
  background-color: black;
  color: #8a90a3 !important;
  list-style-type: circle;
}

.paragraphText {
  color: #8a90a3 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  font-family: 'Graphik Regular' !important;
  line-height: 22px !important;
}

.headerRow {
  margin: 2rem 0 0 0;
  text-align: center;
}
.headerRow span {
  color: #8c8d92;
  font-size: 24px;
  font-family: 'Druk Wide, sans-serif';
}
.headerRow h1 {
  color: white;
  font-size: 42px;
  font-family: 'Druk Wide, sans-serif';
}
.headerRow p {
  color: #ffffff !important;
  font-family: 'Graphik Regular', sans-serif;
  font-size: 14px;
}

@media (max-width: 992px) {
  .headerRow h1 {
    color: white;
    font-size: 32px;
    font-family: 'Druk Wide, sans-serif';
  }
}
