.section {
  color: white;
  font-family: 'Druk Wide, sans-serif';
  font-weight: 400;
  font-size: 10px;
}
.verifyButton {
  margin-top: 1rem;
  height: 32px;
  color: black;
  border: transparent;
  background-color: var(--beans-color-primary);
  font-family: 'Druk Wide, sans-serif';
  font-weight: 400;
  border-radius: 12px;
  font-size: 12px;
}

h2 {
  color: white;
  font-family: 'Druk Wide, sans-serif';
  font-weight: 700;
  font-size: 30px;
  padding-bottom: 1rem;
}

.verifyButton:hover,
.verifyButton:active,
.verifyButton:focus,
.verifyButton:disabled {
  color: var(--beans-color-primary) !important;
  filter: brightness(var(--beans-brightness-percentage)) !important;
  color: black;
  outline: none !important;
  text-decoration: none;
}

.verifySuccess {
  padding: 1em;
  background-color: lightgreen;
  border: 1px green solid;
  border-radius: 12px;
}

.verifyFailure {
  padding: 1em;
  background-color: lightcoral;
  border: 1px red solid;
  border-radius: 12px;
}

.verifyInput {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  height: 40px;
  color: #8a90a3;
  border-radius: 12px !important;
  box-shadow: inset 0px 0px 0px 1px #fff;
  background-color: #222429;
  outline: none !important;
  box-shadow: none !important;
  font-weight: 500;
  font-family: 'Graphik Regular';
  font-size: 14px;
  transition: all 0.2s ease-in-out;
  border: none;
}

.verifyInput:focus {
  box-shadow: inset 0px 0px 0px 1px var(--brand-cool-dark-text) !important;
}

.verifyInput:focus-within {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  height: 40px;
  color: white;
  border-radius: 12px !important;
  box-shadow: inset 0px 0px 0px 1px #676c7a !important;
  background-color: #222429;
  font-weight: 500;
  font-family: 'Graphik Regular';
  font-size: 14px;
  transition: all 0.2s ease-in-out;
  border: none;
}
