.wrapper {
  display: flex;
  flex: 1;
}
.container {
  width: 100%;
  padding-top: 30px;
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
}

.container a,
.container a:focus {
  font-weight: 500;
  font-family: 'Graphik Regular';
  font-size: 14px;
  text-decoration: none;
  color: var(--beans-color-primary);
  transition: all 0.15s ease-in-out;
}

.container a:hover {
  text-decoration: underline;
  color: var(--beans-color-primary);
  filter: brightness(var(--beans-brightness-percentage));
}

.linkWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 3rem;
}

@media (max-width: 992px) {
  .container {
    padding-top: 0px;
  }

  .linkWrapper {
    display: inline;
    text-align: center;
  }
  .linkWrapper div {
    padding-bottom: 20px;
  }
}
