.signedPayload {
  padding-top: 1em;
}

/* div {
  color: white;
} */

.loadingContent {
  color: var(--beans-color-primary);
  filter: brightness(var(--beans-brightness-percentage));
  font-family: 'Druk Wide, sans-serif';
  font-weight: 400;
  font-size: 14px;
}
