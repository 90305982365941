.wrapper {
  height: 32px;
  border-radius: 12px;
  font-size: 12px;
  font-family: 'Druk Wide, sans-serif';
  font-weight: 400;
  padding: 0px 10px 0px 10px;
  transition: all 0.125s ease-in-out;
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.treasuryHeader {
  font-size: 12px;
  margin-left: 0.25rem;
  margin-right: 0.4rem;
  /* margin-top: 1px; */
}

.treasuryBalance {
  font-family: 'Druk Wide, sans-serif', 'Helvetica Neue', Arial !important;
  font-size: 12px;
  letter-spacing: 0.3px;
  margin-left: 0.4rem;
  margin-right: 0.25rem;
}

@media (max-width: 370px) {
  .wrapper {
    margin-left: -0.5rem;
    padding: 0px 7px 0px 6px;
  }

  .treasuryHeader {
    display: none;
  }
}

.coolInfo .treasuryBalance {
  /* color: var(--brand-cool-dark-text); */
}

.treasuryHeader {
  /* opacity: 0.5; */
}

.button:hover {
  cursor: pointer;
}

.coolInfo {
  border-radius: 12px;
  color: var(--brand-cool-dark-text);
}

.coolInfo:hover {
  background: #222429;
  color: white;
}
