.beanHolderEtherscanLinkCool {
  font-family: 'Druk Wide, sans-serif';
  font-weight: 400;
  color: var(--beans-color-primary);
  text-decoration: none;
  transition: all 0.15s ease-in-out;
}

.beanHolderEtherscanLinkCool:hover {
  color: var(--beans-color-primary) !important;
  filter: brightness(110%);
}

.beanHolderInfoContainer {
  display: inline;
  width: 350px;
  height: 35px;
}

.beanHolderLoading {
  opacity: 0.5;
}
