.clickable {
  cursor: pointer;
}

.walletConnectData {
  font-size: 15px;
  padding-top: 1.5rem;
  padding-bottom: 0rem;
  margin-bottom: -1.25rem;
  opacity: 0.9;
  transition: all 0.15s ease-in-out;
  color: #8a90a3;
}

.walletConnectData:hover {
  opacity: 1;
  cursor: pointer;
}

.formTextColor {
  color: #ffffff;
}

.continueBtn:active,
.continueBtn:focus,
.continueBtn {
  width: 100%;
  height: 3rem;
  font-weight: 400;
  font-size: 12px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 12px;
  background-color: var(--beans-color-primary);
  color: black;
  border: var(--brand-dark-red);
  box-shadow: none !important;
  font-family: 'Druk Wide, sans-serif';
}

.continueBtn:hover {
  background-color: var(--beans-color-primary);
  filter: brightness(var(--beans-brightness-percentage));
  box-shadow: none;
  color: black;
  transition: all 0.125s ease-in-out;
}
