.link,
.link:hover,
.link:active {
  color: var(--beans-color-primary);
  text-decoration: none;
}
.link:hover {
  text-decoration: underline;
  text-decoration: none;
}
