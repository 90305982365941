.votePage {
  color: white;
  /* margin-left: 0.4rem; */
}

.votePage a {
  color: var(--beans-color-primary);
  filter: brightness(var(--beans-brightness-percentage));
  font-family: 'Druk Wide, sans-serif';
  font-weight: 400;
  text-decoration: none;
}

.votingText {
  color: #8a90a3 !important;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Graphik Regular';
  line-height: 18px;
}

.proposal {
  margin-top: 1em;
  background-color: black;
}

.backArrow {
  height: 1rem;
}

.votingButton {
  margin-top: 1rem;
  font-family: 'Druk Wide, sans-serif';
  font-weight: 400;
  font-size: 10px;
  background-color: black;
  border: 1px solid var(--brand-cool-border);
  border-radius: 12px;
  color: var(--brand-warm-dark-text);
  padding: 0px 12px 0px 12px;
  transition: all 0.2s ease-in-out;
  color: white;
  height: 36px;
  outline: 0 !important;
}

.votingButton:focus,
.votingButton:focus-visible,
.votingButton:hover,
.votingButton:active {
  background-color: #222429;
  border: 1px solid var(--brand-cool-border);
  box-shadow: none;
  outline: 0 !important;
}

.numberVotes {
  margin-top: 1rem;
  font-family: 'Druk Wide, sans-serif';
  font-weight: 400;
  font-size: 10px;
}

.numberVotes span:first-child {
  color: var(--beans-color-primary);
}

.voteCountCard {
  margin-top: 1rem;
  background-color: black;
  border: 1px solid #454852;
  border-radius: 12px;
}

.proposalId {
  margin: 1rem 0;
  font-family: 'Druk Wide, sans-serif';
  font-weight: 400;
  color: white;
}

.voteCountCard p {
  font-family: 'Druk Wide, sans-serif';
  font-weight: 400;
  font-size: 10px;
  /* display: flex; */
  /* justify-content: space-between; */
}

.section {
  font-family: 'Graphik Regular';
  font-weight: 500;
  word-wrap: break-word;
  padding-top: 2rem;
  margin-top: 2rem;
}

.section a {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Graphik Regular';
}

.section span {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Graphik Regular';
  color: #8a90a3;
}

.section h5 {
  font-size: 16px;
  font-weight: 500;
  font-family: 'Graphik Regular';
}

.section ol {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Graphik Regular';
  color: #8a90a3;
}

.section ol li a {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Graphik Regular';
}

.markdown h1 {
  margin-top: 1rem;
  font-size: 20px;
  font-family: 'Druk Wide, sans-serif';
  font-weight: 400;
}

.markdown h2 {
  color: #8a90a3;
  line-height: 18px;
  margin-top: 1rem;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Graphik Regular';
}

.markdown h3 {
  font-size: 1.3rem;
}

.markdown img {
  max-width: 100%;
  height: auto;
}

.voterIneligibleAlert {
  margin: 1rem 0 0 0;
  background: #49d782 !important;
  border-radius: 12px;
  border: none;
  font-family: 'Druk Wide, sans-serif';
  font-weight: 400;
  font-size: 12px;
}

.blockRestrictionAlert {
  color: #8a90a3;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Graphik Regular';
  margin: 1rem 0 0 0;
  color: #8a90a3;
  background-color: #222429;
  border: none;
  border-radius: 12px !important;
}

.spinner {
  margin-top: 2px;
}
