.wrapper {
  height: 32px;
  font-size: 12px;
  line-height: 12px;
  font-family: 'Druk Wide, sans-serif';
  font-weight: 400;
  padding: 0px 12px 0px 12px;
  transition: all 0.125s ease-in-out;
  box-shadow: none !important;
}

.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.icon {
  margin-right: 0.4rem;
}

.icon > svg {
  max-height: 14px;
  opacity: 0.5;
}

@media (max-width: 992px) {
  .icon > svg {
    max-height: 17px;
  }
}

.button:hover {
  cursor: pointer;
}

.coolInfo {
  border-radius: 12px;
  color: var(--brand-cool-dark-text);
}

.coolInfo:hover {
  background: #222429;
  color: white;
}

.coolWallet {
  background-color: black;
  border: 1px solid var(--brand-cool-border);
  border-radius: 12px;
  color: var(--brand-warm-dark-text);
  padding: 0px 12px 0px 12px;
  transition: all 0.2s ease-in-out;
  color: white;
  height: 36px;
}

.coolWallet:hover {
  background-color: #222429;
  color: white;
}

@media (max-width: 992px) {
  .wrapper,
  .button {
    height: 36px;
    font-size: 14px;
  }

  .coolWallet {
    width: 50%;
    margin: auto;
  }
}
