.modal {
  position: fixed;
  top: 15vh;
  z-index: 100;
  padding: 2rem;
  text-align: center;
  border-radius: 15px;
  left: calc(50% - 12.5rem);
  width: 25rem;
}

.displayBeanFooter {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.displayBeanFooter span {
  color: white;
  font-weight: bold;
  margin-bottom: 1rem;
}
.displayBeanFooter button {
  width: 50%;
  height: 40px;
  color: white;
  background-color: black !important;
  font-weight: 400;
  font-family: 'Druk Wide, sans-serif';
  font-size: 12px;
  border-radius: 12px;
  border: 1px solid white !important;
}

.displayBeanFooter button:hover,
.displayBeanFooter button:active,
.displayBeanFooter button:focus {
  background: #222429 !important;
  box-shadow: none !important;
}

.beanImg {
  border-radius: 16px;
}
.beanWrapper {
  margin-bottom: 1rem;
}

@media (max-width: 992px) {
  .modal {
    width: 80% !important;
    left: 10% !important;
  }
}
