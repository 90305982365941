@media (max-width: 992px) {
  .emergencySettleWrapper {
    text-align: center;
    margin-left: 0.5rem;
  }
}

@media (max-width: 660px) {
  .emergencySettleWrapper {
    text-align: center;
    margin-left: 0rem;
  }
}

.emergencySettleButton {
  padding-left: 1rem;
  padding-right: 1rem;
  height: 40px;
  color: black !important;
  border: transparent;
  background-color: var(--beans-color-primary);
  font-family: 'Druk Wide, sans-serif';
  font-weight: 400;
  border-radius: 12px;
  font-size: 12px;
}
.emergencySettleButton:hover,
.emergencySettleButton:active,
.emergencySettleButton:focus {
  background-color: var(--beans-color-primary) !important;
  filter: brightness(var(--beans-brightness-percentage)) !important;
  color: black;
  outline: none !important;
  text-decoration: none;
}

.emergencySettleButton:disabled {
  background-color: var(--beans-color-primary) !important;
  filter: brightness(80%) !important;
  color: black;
  outline: none !important;
  text-decoration: none;
  cursor: not-allowed;
}
