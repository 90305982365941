.popover {
  max-width: 600px;
  background-color: black;
  color: white;
  border-radius: 12px;
  color: white;
  font-weight: 500;
  font-family: 'Graphik Regular';
  font-size: 14px;
}

.popoverheader {
  background-color: black;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border: 1px solid #454852 !important;
  color: white;
  font-weight: 500;
  font-family: 'Graphik Regular';
  font-size: 14px;
}

.popoverbody {
  background-color: black;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border: 1px solid #454852 !important;
  color: white;
  font-weight: 500;
  font-family: 'Graphik Regular';
  font-size: 14px;
}

.transactionDetails {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  border: 1px solid #454852 !important;
  border-radius: 12px !important;
  color: white;
  font-weight: 500;
  font-family: 'Graphik Regular';
  font-size: 14px;
}

.removeTransactionButton {
  border: none;
  background: none;
  outline: none !important;
  box-shadow: none !important;
}

.removeTransactionButton img {
  width: 1rem;
}
