.beanWrapper {
  /* align-self: flex-end; */
  /* margin-bottom: auto; */
  width: 100%;
}

.beanContentCol {
  display: flex;
  padding-left: 100px;
}

.auctionActivityCol {
  padding-right: 5rem;
  padding-bottom: 0rem;
  min-height: 520px;
  align-self: flex-end !important;
}

@media (max-width: 992px) {
  .beanWrapper {
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
  }
  .auctionActivityCol {
    padding-top: 5%;
    padding-right: 5%;
    padding-left: 5%;
    width: 100%;
    min-height: 0;
    background-color: black;
  }
}

@media (max-width: 568px) {
  .auctionActivityCol {
    width: 100%;
    margin-left: unset;
    margin-right: unset;
    padding-bottom: 2rem;
    padding-top: 1rem;
    padding-right: unset;
    padding-left: 0;
    min-height: 0;
    background: black;
  }

  .beanWrapper {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;
  }

  .beanContentCol {
    padding: 0rem;
  }
}
