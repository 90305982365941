.navArrowsContainer {
  /* position: absolute; */
  display: flex;
}

.leftArrowCool {
  /* -webkit-appearance: none;
  padding: 0;
  display: inline-block;
  width: 1.25rem;
  height: 1.25rem;
  border: none;
  background-size: contain;
  background-repeat: no-repeat;
  font-size: large;
  border-radius: 50%;
  color: var(--brand-cool-dark-text);
  border: 2px solid white;
  background-color: black;
  font-weight: bold; */
  background: transparent;
  border: none;
}

.leftArrowCool img {
  transform: scale(1.3);
}

.rightArrowCool {
  /* margin-left: 0.3rem;
  -webkit-appearance: none;
  padding: 0;
  display: inline-block;
  width: 1.25rem;
  height: 1.25rem;
  border: none;
  background-size: contain;
  background-repeat: no-repeat;
  font-size: large;
  color: var(--brand-cool-dark-text);
  border: 2px solid white;
  background-color: black;
  border-radius: 50%;
  font-weight: bold; */
  background: transparent;
  border: none;
}

.rightArrowCool img {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg) scale(1.3);
}

/* Disable hover on mobile because it was leading to
buttons looking disabled when they were not */
@media (min-width: 992px) {
  .leftArrowCool:hover,
  .rightArrowCool:hover {
    opacity: 0.9;
    transform: scale(1.1);
    /* background-color: var(--brand-gray-hover); */
  }
}

.leftArrowCool:disabled,
.rightArrowCool:disabled {
  opacity: 0.55;
  cursor: not-allowed;
}

@-moz-document url-prefix() {
  .navArrowsContainer {
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
}
