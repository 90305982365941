.bidHistoryWrapper {
  background-color: var(--brand-cool-accent);
  display: flex;
  border-radius: 12px !important;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.bidHistoryWrapperCool {
  display: flex;
  border-radius: 12px !important;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  color: white;
}

.bidHistory {
  margin-left: 0.5rem;
  color: var(--brand-color-cool);
  font-family: 'Druk Wide, sans-serif';
  font-weight: normal;
  font-size: 1rem;
  font-size: 12px;
}

.bidHistoryCool {
  margin-left: 0.5rem;
  color: var(--beans-color-primary);
  font-family: 'Druk Wide, sans-serif';
  font-weight: 400;
  font-size: 1rem;
  font-size: 16px;
}

.bidHistoryCool {
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

.bidHistoryCool:hover {
  filter: brightness(110%);
  color: var(--beans-color-primary);
}

.bidHistoryWrapperCool:hover {
  filter: brightness(70%);
  /* color: var(--beans-color-primary); */
}

.bidHistoryWrapper:hover {
  background-color: var(--brand-gray-hover);
}
