.beanInfoHeader {
  display: inline-block;
  padding-left: 0rem;
  font-family: 'Druk Wide, sans-serif';
  color: white;
}

@media only screen and (max-width: 600px) {
  .beanInfoHeader {
    padding-top: 1.5rem;
    margin-bottom: 0;
    color: white;
  }
}

.beanInfoHeader h2 {
  font-size: 56px;
  margin-bottom: 18px;
  color: white;
}

.beanInfoHeader h3 {
  font-size: 24px;
  margin-bottom: '0px';
  color: white;
}

.beanInfoRow {
  font-family: 'Druk Wide, sans-serif';
  font-weight: 400;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  font-size: 12px;
  display: flex;
  color: white;
}

.beanInfoRowText {
  padding-left: 4;
  font-family: 'Druk Wide, sans-serif';
  font-family: 400;
  color: white;
}
