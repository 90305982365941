.proposalEditor {
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 0.5rem 1rem 1rem 1rem;
  border: 1px solid #454852 !important;
  border-radius: 12px !important;
  outline: none !important;
  box-shadow: none !important;
}

.proposalEditor small {
  font-family: 'Druk Wide, sans-serif';
  font-weight: 400;
  color: white;
  font-size: 10px;
  font-style: normal;
  padding-bottom: 10px;
}

.titleInput,
.bodyInput {
  /* padding: 0; */
  border: none;
  width: 100% !important;
  outline: none !important;
  box-shadow: none !important;
}

.titleInput {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  width: auto;
  height: 40px;
  color: #8a90a3;
  border-radius: 12px !important;
  box-shadow: inset 0px 0px 0px 1px #fff;
  background-color: #222429;
  outline: none !important;
  box-shadow: none !important;
  font-weight: 500;
  font-family: 'Graphik Regular';
  font-size: 14px;
  transition: all 0.2s ease-in-out;
  border: none;
  margin-bottom: 10px;
}

.titleInput:focus {
  box-shadow: inset 0px 0px 0px 1px var(--brand-cool-dark-text) !important;
}

.titleInput:focus-within {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  height: 40px;
  color: white;
  border-radius: 12px !important;
  box-shadow: inset 0px 0px 0px 1px #676c7a !important;
  background-color: #222429;
  font-weight: 500;
  font-family: 'Graphik Regular';
  font-size: 14px;
  transition: all 0.2s ease-in-out;
  border: none;
}

.bodyInput {
  min-height: 340px !important;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  width: auto;
  height: 40px;
  color: #8a90a3;
  border-radius: 12px !important;
  box-shadow: inset 0px 0px 0px 1px #fff;
  background-color: #222429;
  outline: none !important;
  box-shadow: none !important;
  font-weight: 500;
  font-family: 'Graphik Regular';
  font-size: 14px;
  transition: all 0.2s ease-in-out;
  border: none;
}

.bodyInput:focus {
  box-shadow: inset 0px 0px 0px 1px var(--brand-cool-dark-text) !important;
}

.bodyInput:focus-within {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  height: 40px;
  color: white;
  border-radius: 12px !important;
  box-shadow: inset 0px 0px 0px 1px #676c7a !important;
  background-color: #222429;
  font-weight: 500;
  font-family: 'Graphik Regular';
  font-size: 14px;
  transition: all 0.2s ease-in-out;
  border: none;
}

.divider {
  width: 100%;
  margin: 0 0 0.5rem 0;
}

.previewArea {
  padding: 0.5rem 1rem 1rem 1rem;
  border: 1px solid #454852 !important;
  border-radius: 12px !important;
  outline: none !important;
  box-shadow: none !important;
  color: white;
  font-weight: 500;
  font-family: 'Graphik Regular';
}

.markdown h1 {
  font-size: 1.7rem;
  margin-top: 1rem;
  font-weight: bold;
}

.markdown h2 {
  font-size: 1.5rem;
  margin-top: 1rem;
  font-weight: bold;
}

.markdown h3 {
  font-size: 1.3rem;
}

.markdown img {
  max-width: 100%;
  height: auto;
}
