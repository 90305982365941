.sectionClass {
  padding-top: 50px;
}

.headerWrapper h1 {
  font-family: 'Druk Wide, sans-serif';
  font-weight: 700;
  font-size: 30px;
  color: white;
}

.headerWrapper p {
  font-family: 'Druk Wide, sans-serif';
  font-weight: 400;
  line-height: 28px;
  /* margin: 3rem 0; */
}

.accordionItem {
  font-size: 14px !important;
  font-weight: 500 !important;
  font-family: 'Graphik Regular' !important;
  line-height: 18px !important;
  background-color: black;
  color: #8a90a3 !important;
}

@media (max-width: 992px) {
  .headerWrapper {
    padding: 1rem;
  }
}

.accordionItem {
  margin-bottom: 2.5rem;
  border: none;
}

.aboutList li {
  font-size: 14px !important;
  font-weight: 500 !important;
  font-family: 'Graphik Regular' !important;
  line-height: 18px !important;
  background-color: black;
  color: #8a90a3 !important;
  list-style-type: circle;
}

.aboutText {
  color: #8a90a3 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  font-family: 'Graphik Regular' !important;
  line-height: 22px !important;
}

.accordionItem,
.headerWrapper {
  font-size: 1.3rem;
}

.accordionHeader button:hover {
  /* color: var(--brand-dark-red) !important; */
  /* filter: brightness(110%); */
}

.accordionHeader button {
  border: none;
  background-color: transparent;
  font-family: 'Druk Wide, sans-serif';
  font-weight: 700;
  font-size: 30px !important;
  cursor: pointer;
  /* line-height: normal; */
  /* padding-top: 0;
  padding-bottom: 0; */
}

.accordionHeader {
  /* color: pink; */
  background-color: black;
  box-shadow: none !important;
  font-size: 30px !important;
}

.accordionHeader button:not(.collapsed) {
  color: white;
  background-color: black;
  box-shadow: none !important;
  font-size: 30px !important;
}

/* override bootstrap color */
.accordionHeader button:not(.collapsed)::after {
  /* background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23676C7A'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important; */
  background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16 29.3333C23.3638 29.3333 29.3333 23.3638 29.3333 16C29.3333 8.63616 23.3638 2.66663 16 2.66663C8.63616 2.66663 2.66663 8.63616 2.66663 16C2.66663 23.3638 8.63616 29.3333 16 29.3333Z' stroke='%23676C7A' stroke-width='2.66667' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M10.6666 16L16 21.3333L21.3333 16' stroke='%23676C7A' stroke-width='2.66667' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M16 10.6666V21.3333' stroke='%23676C7A' stroke-width='2.66667' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}

.accordionHeader button:focus {
  /* color: var(--brand-dark-red) !important; */
  box-shadow: none !important;
}

@media (max-width: 992px) {
  .accordionHeader button {
    padding-left: 20px;
  }

  .accordionItem {
    margin-bottom: 0.5rem;
    border: none;
  }

  .accordionHeader button:not(.collapsed) {
    font-size: 14px !important;
  }
}
