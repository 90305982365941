.playgroundContainer {
  background-color: black !important;
}

.headerRow {
  margin: 2rem 0;
  text-align: center;
}
.headerRow span {
  color: #8c8d92;
  font-size: 24px;
  font-family: 'Druk Wide, sans-serif';
}
.headerRow h1 {
  color: white;
  font-size: 42px;
  font-family: 'Druk Wide, sans-serif';
}
.headerRow p {
  color: #8a90a3 !important;
  font-family: 'Graphik Regular', sans-serif;
  font-size: 14px;
}
.generateBtn:active,
.generateBtn:focus,
.generateBtn {
  width: 100%;
  height: 3rem;
  font-weight: 400;
  font-size: 12px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 12px;
  background-color: var(--beans-color-primary);
  color: black;
  border: var(--brand-dark-red);
  box-shadow: none !important;
  font-family: 'Druk Wide, sans-serif';
}

.generateBtn:hover {
  background-color: var(--beans-color-primary);
  filter: brightness(var(--beans-brightness-percentage));
  box-shadow: none;
  color: black;
  transition: all 0.125s ease-in-out;
}

.traitForm {
  height: 4.5rem;
  font-family: 'Druk Wide, sans-serif';
}

.traitFormBtn {
  height: 100% !important;
  width: 100%;
  margin: 0.5rem 0;
  padding-left: 15px;
  border-radius: 12px;
  background-color: #191b1f !important;
  /* border-color: #e2e3e8 !important; */
  border: none;
  font-size: 10px;
  font-weight: 400;
  color: #cccccc;
  /* text-align: right !important; */
}
.traitFormBtn:hover,
.traitFormBtn:focus {
  /* border-color: #e2e3e8 !important;
  background-color: #f4f4f8 !important; */
  box-shadow: none !important;
}

.traitTitle {
  font-size: 10px !important;
  font-weight: 400;
  color: var(--beans-color-primary);
  margin-left: 8px;
}

.floatingLabel {
  font-size: 10px !important;
  font-weight: 400;
  color: var(--beans-color-primary);
  /* margin-bottom: 20px !important; */
  padding-top: 1.2rem;
}
.beanYearsFooter {
  font-size: 14px;
  font-weight: 500;
  color: #8a90a3;
  font-family: 'Graphik Regular', sans-serif;
}
.beanImg {
  border-radius: 16px;
}
.beanImg:hover {
  cursor: pointer;
  transform: scale(1.01);
}
.beanWrapper {
  margin-bottom: 1rem;
}

@media (max-width: 992px) {
  .headerRow h1 {
    color: white;
    font-size: 32px;
    font-family: 'Druk Wide, sans-serif';
  }
}
