.proposalStatus {
  font-family: 'Druk Wide, sans-serif';
  font-weight: 400;
  color: black;
  border-radius: 12px;
  line-height: 13px;
  font-size: 10px;
  border: 0rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.primary {
  background-color: #5c83ff;
}

.pending {
  background-color: #fff95f;
}

.success {
  background-color: #49d782;
}

.danger {
  background-color: #fc6666;
}

.secondary {
  background-color: #a3baff;
}

.votePageProposalStatus {
  margin-top: 0.8rem;
}
