.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(10px);
}

.modal {
  font-family: 'Graphik Regular', sans-serif;
  font-weight: 500;
  position: fixed;
  top: 30vh;
  left: 10%;
  z-index: 100;
  background-color: black;
  backdrop-filter: blur(10px);
  padding: 2rem 2rem;
  text-align: center;
  border-radius: 20px;
  left: calc(50% - 17.5rem);
  width: 35rem;
  color: white;
  border: 2px solid #454852 !important;
  /* margin-bottom: 100px; */
  /* margin-bottom: 200px; */
  /* top: calc(50vh - 300px); */
}

/* .modal::before {
  content:"";
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  border-radius: 15px;
  padding:3px; 
  background:linear-gradient(45deg,#FC6694,#A270FF,#609CFF, #52E0FF); 
  -webkit-mask: 
     linear-gradient(#fff 0 0) content-box, 
     linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
          mask-composite: exclude; 
} */

.modal button {
  border-radius: 12px;
  min-height: 44px;
  padding-top: 7px;
  background-color: #222429;
  color: white;
}

.modal button:hover {
  color: black;
  /* background-color: rgba(255, 255, 255, 1); */
}

.modal h3 {
  font-size: large;
  font-weight: 400;
  color: white;
  font-family: 'Druk Wide, sans-serif', sans-serif;
  padding-bottom: 1rem;
}

.content {
  /* padding: 1rem; */
  /* max-height: 50vh; */
  /* overflow-y: auto; */
}

.modal .closeButton {
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
  border: 0 none;
  background-color: transparent;
  width: 32px;
  height: 32px;
  padding-top: 2px;
}

.modal .closeButton img {
  height: 1rem;
  width: 1rem;
  /* opacity: 0.5; */
  transition: all 0.125s ease-in-out;
  margin-top: -4px;
}

.modal .closeButton img:hover {
  opacity: 1;
}

.modal button img {
  margin-top: -2px;
  width: 1.5rem;
  height: auto;
}

@media (max-width: 992px) {
  .modal {
    margin: auto !important;
    top: 20vh;
    left: 0;
    right: 0;
    /* bottom: 0; */
    width: 95%;
    /* max-height: 100%; */
    border-bottom-left-radius: 12;
    border-bottom-right-radius: 12;
  }
}
