.warningText {
  color: white;
  /* color: #8A90A3 !important; */
  font-size: 14px;
  font-weight: 500;
  font-family: 'Graphik Regular';
  text-align: center;
}

.spinner {
  margin-top: 2px;
}
