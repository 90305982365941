.altWrapper {
  text-align: center;
}

.bidCollection {
  display: grid;
  text-align: start;
  list-style-type: none;
  row-gap: 0.5rem;
  padding: 0;
  background-color: black;
}

.bidRow {
  padding: 1rem;
  border-bottom: 1px solid rgba(211, 211, 211, 0.322);
}

.bidItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.leftSectionWrapper {
  display: flex;
  flex-direction: column;
}

/* .bidRowCool {
  color: var(--beans-color-primary);
} */

.bidder {
  font-weight: 400;
  padding-top: 4px !important;
  font-size: 12px;
  color: var(--beans-color-primary) !important;
  filter: brightness(var(--beans-brightness-percentage)) !important;
  font-family: 'Druk Wide, sans-serif';
  font-weight: 400;
}

.bidDate {
  color: #8a90a3;
  font-size: 12px;
  font-family: 'Graphik Regular';
}

.rightSectionWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.bidAmount {
  font-family: 'Druk Wide, sans-serif';
  font-weight: 400;
  margin-right: 1rem;
  font-size: 12px;
  color: white;
}

.linkSymbol,
.linkSymbol a {
  color: var(--brand-black);
}

.linkSymbol a:hover {
  color: gray;
}
