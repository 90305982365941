.createProposalPage a {
  color: var(--beans-color-primary);
  filter: brightness(var(--beans-brightness-percentage));
  font-family: 'Druk Wide, sans-serif';
  font-weight: 400;
  text-decoration: none;
}

.createProposalForm {
  border-radius: 5px;
  /* padding: 1rem 2.5rem; */
  margin-top: 1em;
  background-color: black;
}

.heading {
  margin: 1rem 0;
  font-size: 30px;
  font-weight: 400;
  font-family: 'Druk Wide, sans-serif';
  color: white;
}

.section {
  border-top: 1px solid #e9ecef;
  word-wrap: break-word;
  padding-top: 2rem;
  margin-top: 2rem;
}

.addTransactionButton,
.createProposalButton {
  margin-top: 1rem;
  height: 32px;
  color: black;
  border: transparent;
  background-color: var(--beans-color-primary) !important;
  font-family: 'Druk Wide, sans-serif';
  font-weight: 400;
  border-radius: 12px;
  font-size: 12px;
}

.addTransactionButton:hover,
.addTransactionButton:active,
.addTransactionButton:focus,
.createProposalButton:hover,
.createProposalButton:active,
.createProposalButton:focus {
  background-color: var(--beans-color-primary) !important;
  color: black;
  outline: none !important;
  text-decoration: none;
}

.addTransactionButton:disabled,
.createProposalButton:disabled {
  background-color: var(--beans-color-primary) !important;
  color: black;
  outline: none !important;
  text-decoration: none;
  cursor: not-allowed;
}

.voterIneligibleAlert {
  background: #222429;
  color: #8a90a3;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Graphik Regular';
  line-height: 18px;
  border-radius: 12px;
  border: none;
}

.voterIneligibleAlert span {
  color: white;
}
