.altWrapper {
  text-align: center;
}

.bidCollection {
  display: grid;
  text-align: start;
  list-style-type: none;
  row-gap: 0.5rem;
  padding: 0;
  margin-bottom: 8px;
  margin-top: 1rem;
}

.bidRowCool {
  font-family: 'Druk Wide, sans-serif';
  font-size: 1.1rem;
  font-weight: 400;
  transition: all 0.2s ease-in-out;
}

.bidRowCool a {
  color: var(--beans-color-primary) !important;
  font-weight: bold;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

.bidRowCool a:hover {
  filter: brightness(110%);
}

.bidRowCool:hover {
  filter: brightness(105%);
}

.bidItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.leftSectionWrapper {
  display: flex;
  flex-direction: column;
}

.bidder {
  font-family: 'Druk Wide, sans-serif';
  font-weight: 400;
  color: var(--beans-color-primary) !important;
  filter: brightness(var(--beans-brightness-percentage)) !important;
}

.bidder > div {
  font-family: 'Druk Wide, sans-serif';
  font-weight: 400;
  font-size: 12px;
}

.bidder > div > div {
  font-family: 'Druk Wide, sans-serif';
  font-weight: 400;
  font-size: 12px;
}

.bidRowCool .bidder > div > div {
  color: var(--beans-color-primary);
  filter: brightness(var(--beans-brightness-percentage));
}

.bidDate {
  display: none;
  color: gray;
}

.rightSectionWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.bidAmount {
  font-family: 'Druk Wide, sans-serif', 'Helvetica Neue', Arial !important;
  font-weight: 400;
  margin-right: 1rem;
  font-size: 12px;
  padding-top: 2px;
}

.bidRowCool .bidAmount {
  color: var(--brand-cool-dark-text);
}

.linkSymbol {
  color: var(--beans-color-primary);
}

.linkSymbol:hover {
  color: var(--brand-cool-dark-text);
}

@media (max-width: 992px) {
  .bidRow {
    border-radius: 12px;
  }

  .bidAmount .linkSymbol .bidder {
    font-size: 16px;
  }

  .bidRowCool .bidder > div {
    color: var(--beans-color-primary);
    filter: brightness(var(--beans-brightness-percentage));
    font-size: 12px;
    font-weight: 400;
  }

  .bidRowCool .bidder > div > div {
    color: var(--beans-color-primary);
    filter: brightness(var(--beans-brightness-percentage));
  }
}
